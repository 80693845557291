import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import { modalDeliverySteps } from '../../actions/modals';
import Loader from '../../components/Loader';
// Hocs
import Modal from '../../hocs/Modal';
// Styles
import { Row } from '../../styles/align';
import { BtnGroup, Button } from '../../styles/forms';
import { Loading } from '../style';
import { StyleDeliverySteps } from './style';

class DeliverySteps extends Component {
  state = {
    loading: false,
  };

  handleCallback = () => {
    this.setState({
      loading: true,
    });
    if (this.props.callback !== undefined) {
      this.props.callback();
      this.props.modalDeliverySteps();
    } else {
      this.props.modalDeliverySteps();
    }
  };

  render() {
    return (
      <StyleDeliverySteps>
        {this.state.loading ? (
          <Loading>
            <Loader />
          </Loading>
        ) : (
          ''
        )}
        <Row><p>Em relação aos prazos:</p></Row>
        <Row>
          <p>
            <b>1) Análise de Arquivo:</b> Após a aprovação da arte, seu arquivo
            passa por essa etapa, podendo levar de 1 a 2 dias úteis. Se houver
            problemas, o prazo pode se estender.
          </p>
        </Row>
        <Row>
          <p>
            <b>2) Produção do Pedido:</b> Inicia quando o status do item é
            "Produção", conforme os prazos indicados na página do produto para
            impressão, corte e finalização.
          </p>
        </Row>
        <Row>
          <p>
            <b>3) Entrega do Pedido:</b> Desde a saída até a entrega, o prazo
            varia de acordo com a região e o método de entrega escolhido.
            Garantimos uma experiência de compra tranquila e eficiente em todas
            as etapas."
          </p>
        </Row>
        <Row>
          <span>Avisos</span>
        </Row>
        <Row>
          <p>
            Após o pedido entrar em <b>Produção</b>, não é possível solicitar o
            reenvio de arquivos.
          </p>
        </Row>
        <Row>
          <p>
            <b>
              O prazo de produção informado é uma estimativa e pode sofrer
              ajustes conforme o andamento do processo.
            </b>
          </p>
        </Row>
        <Row>
          <BtnGroup>
            <Button onClick={() => this.props.modalDeliverySteps()}>
              Cancelar
            </Button>
            <Button onClick={this.handleCallback}>Confirmar</Button>
          </BtnGroup>
        </Row>
      </StyleDeliverySteps>
    );
  }
}

const mapStateToProps = state => {
  return {
    callback: state.modals.modalDeliverySteps.callback,
    data: state.modals.modalDeliverySteps.data,
    text: state.modals.modalDeliverySteps.text,
  };
};

DeliverySteps = connect(mapStateToProps, { modalDeliverySteps })(DeliverySteps);

DeliverySteps = Modal(DeliverySteps);

export default DeliverySteps;
