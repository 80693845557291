import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, Field } from 'redux-form';

// Node
import { toast } from 'react-toastify';

// actions
import { fetchDependencies } from '../../actions/filter';

// Validate
import renderField from '../../formValidate/renderField';
import { required } from '../../formValidate';
import { onlyNumbers, numberFormat } from '../../utils';

// Services
import { calcularLombada } from '../../services/filter';

// Utils
import { ERROR } from '../../utils/constMessage';

// Styles
import {
  StyleCalcLombada,
  StyleForm,
  StyleText,
  WrapperPages,
  WrapperCheckbox,
  Result,
} from './style';
import { TitlePage } from '../../styles/titles';
import { Row, Col } from '../../styles/align';
import { Wrapper, Label, BtnGroup, ButtonPrimary } from '../../styles/forms';

class CalcLombada extends Component {
  state = {
    result: 0,
  };

  componentDidMount() {
    this.props.fetchDependencies({ tipo_produto: 7, atributo: 1 });

    const { tipo_papel, total_pag, is_capa_dura } = this.props.match.params;

    if (!this.state.result && tipo_papel) {
      this.submit(
        {
          papel: tipo_papel ? tipo_papel : 0,
          qtd_paginas: total_pag ? total_pag : '',
          capa_dura: is_capa_dura === '1' ? true : false,
        },
        false
      );
    }
  }

  submit = async (value, showMessage = true) => {
    const response = await calcularLombada(value);

    if (response.status === 200) {
      this.setState({
        result: response.data.data,
      });
      if (showMessage) {
        toast.success(response.data.message);
      }
    } else {
      if (showMessage) {
        toast.error(ERROR);
      }
    }
  };

  render() {
    const { handleSubmit, dependencies } = this.props;

    if (!dependencies) {
      return '';
    }

    return (
      <StyleCalcLombada>
        <TitlePage>
          <span>Cálculo de Lombada</span>
        </TitlePage>
        <Row>
          <StyleForm>
            <p>Selecione o tipo de papel e o numero de páginas</p>
            <Col>
              <Form onSubmit={handleSubmit(this.submit)}>
                <Wrapper>
                  <Label htmlFor={'papel'}>Tipo de Papel</Label>
                  <Field
                    name={'papel'}
                    id={'papel'}
                    type={'select'}
                    typefield={'select'}
                    component={renderField}
                    validate={required}
                    firstOption={
                      <option value={0} disabled>
                        Selecione o tipo de Papel
                      </option>
                    }
                    options={dependencies.data.map((item, i) => {
                      return (
                        <option key={i} value={item.pk_dependencia_atributo}>
                          {item.texto_dependencia_atributo}
                        </option>
                      );
                    })}
                  />
                </Wrapper>
                <WrapperPages>
                  <Label htmlFor={'qtd_paginas'}>Nº de Páginas</Label>
                  <Field
                    name={'qtd_paginas'}
                    id={'qtd_paginas'}
                    component={renderField}
                    normalize={onlyNumbers}
                    validate={required}
                    placeholder={'Nº de Páginas'}
                  />
                </WrapperPages>
                <WrapperCheckbox>
                  <Field
                    name={'capa_dura'}
                    id={'capa_dura'}
                    type={'checkbox'}
                    typefield={'checkbox'}
                    component={renderField}
                  />
                  <Label htmlFor={'capa_dura'}>Material com Capa Dura</Label>
                </WrapperCheckbox>
                <BtnGroup>
                  <ButtonPrimary>Calcular</ButtonPrimary>
                </BtnGroup>
              </Form>
            </Col>
          </StyleForm>
          <StyleText>
            <Col>
              <h2>Importante</h2>
              <ul>
                <li>
                  &#9679; O resultado é calculado a partir do tipo de papel, da
                  gramatura e do número de páginas do material que se deseja
                  imprimir.
                </li>
                <li>
                  &#9679; A unidade de medida utilizada é o milímetro. Ao
                  efetuar os cálculos, tome cuidado para não confundir, por
                  exemplo, 0,10 mm com 1 mm.
                </li>
                <li>
                  &#9679; As micragens dos papéis podem variar ocasionando,
                  consequentemente, variação na medida da lombada. Por isso, os
                  valores aqui apresentados como resultado final são medidas
                  aproximadas.
                </li>
              </ul>
              {this.state.result ? (
                <Result>
                  <span>Resultado</span>
                  <p>{numberFormat(this.state.result, 3)} mm</p>
                </Result>
              ) : (
                ''
              )}
            </Col>
          </StyleText>
        </Row>
      </StyleCalcLombada>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { tipo_papel, total_pag, is_capa_dura } = props.match.params;

  return {
    dependencies: state.filter.dependeciesCalc,
    initialValues: {
      papel: tipo_papel ? tipo_papel : 0,
      qtd_paginas: total_pag ? total_pag : '',
      capa_dura: is_capa_dura === '1' ? true : false,
    },
  };
};

CalcLombada = reduxForm({ form: 'CalcLombada' })(CalcLombada);
CalcLombada = connect(mapStateToProps, { fetchDependencies })(CalcLombada);

export default CalcLombada;
