import styled from 'styled-components';

import { Row } from '../../styles/align';
import { BtnGroup, Button } from '../../styles/forms';
// Styles
import { colors } from '../../styles/variables';
import { Container } from '../style';

/**
 * Formata o Container da Modal de Confirmação de envio de arquivos
 *
 * @extends {Container}
 *
 * Herda estilo para formatar o interior do Container
 * @extends {Row}
 * @extends {BtnGroup}
 * @extends {Button}
 * @extends {colors}
 */
export const StyleConfirmFileUpload = styled(Container)`
  position: relative;
  background-color: ${colors.grey.light};
  padding: 1rem;

  .infoText {
    color: ${colors.error} !important;
  }

  ${Row} {
    text-align: left;
    color: ${colors.grey.darkPrimary};
    padding: 0.5rem 0.5rem;

    p {
      color: ${colors.black.primary};
      padding: 1rem 0;
      line-height: 1.5;

      strong {
        font-weight: 700;
        color: ${colors.black.primary};
      }
    }

    ${BtnGroup} {
      width: 100%;
      justify-content: flex-end;

      ${Button} {
        text-decoration: none;

        &:last-of-type {
          background-color: ${colors.primary};
          color: ${colors.default};
        }
      }
    }
  }
`;
